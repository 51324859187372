import clsx from "clsx";
import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigation } from "react-router-dom";
import scrollToTopInstance from "@/services/ScrollToTop";

export default function Root({ children, className, ...rest }: any) {
  const navigation = useNavigation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    scrollToTopInstance.setTarget(wrapperRef.current!);
  }, [wrapperRef]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (
        wrapperRef.current &&
        navigation.state === "idle" &&
        location.key !== "default"
      ) {
        wrapperRef.current.scrollIntoView();
      }
    }
  }, [location, navigation]);

  return (
    <main
      {...rest}
      ref={wrapperRef}
      className={clsx(className, "antialiase text-left")}
    >
      {navigation.state === "loading" && (
        <div className="fixed inset-0 z-50 grid items-center justify-center grid-cols-1 grid-rows-1 place-items-center">
          <div className="absolute inset-0 z-10 bg-white/90 backdrop-blur-xl"></div>
          <span className="z-20">Laden...</span>
        </div>
      )}

      <Outlet />
    </main>
  );
}
