import { price as formatPrice, isAvailable } from "@/services";
import { Hotspot, Layer, Plot, Status } from "@/types";

import {
  Link as CustomLink,
  LikeButton,
  Placeholder,
  StatusLabel,
} from "@/components";
import {
  faChair,
  faCompass,
  faInfoCircle,
  faLocationCrosshairs,
  faVectorSquare,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import { formatName } from "@/services/Plot";

type CardProps = {
  plot: Plot;
  status: Status;
  layer: Layer;
  hotspot: Hotspot;
  className?: string;
  id?: string;
  as?: React.ElementType;
};

export default function Card({
  plot,
  status,
  layer,
  hotspot,
  className,
  as,
  ...rest
}: Readonly<CardProps>) {
  const Component = as || "div";
  const { projectSlug, phaseSlug } = useParams();
  const {
    id,
    attachments,
    name,
    room_count,
    living_surface,
    sun_position_outdoor,
    price,
    project_type,
  } = plot;
  const available = isAvailable(status);

  return (
    // Note: We HAVE to use grid here because the content determines flex column width, leading to a non-uniform grid
    <Component
      className={clsx(
        className,
        "relative grid auto-rows-min gap-x-4 gap-y-2 grid-cols-card lg:gap-y-6 lg:gap-x-6 xl:gap-x-8 2xl:gap-x-12"
      )}
      {...rest}
    >
      <LikeButton
        plot={plot}
        className="flex absolute top-2.5 left-2.5 text-primary"
      />

      <figure className="relative overflow-hidden col-span-full aspect-video xs:aspect-auto xs:col-span-1">
        <Link
          to={`/projects/${projectSlug}/phases/${phaseSlug}/plots/${id}`}
          className="absolute inset-0 object-cover w-full h-full"
        >
          {attachments.length > 0 && attachments[0]?.original ? (
            <img
              src={attachments[0].original}
              srcSet={attachments[0].srcset}
              sizes="224px"
              alt={name}
              width="600"
              height="400"
              className="object-cover object-center w-full h-full"
              loading="lazy"
            />
          ) : (
            <Placeholder className="object-cover object-center w-full h-full" />
          )}
        </Link>
      </figure>

      <div className="flex flex-col gap-y-2 col-span-full xs:shrink-0 xs:grow-0 xs:col-start-2 lg:gap-y-6 ">
        {/* Title */}
        <div className="flex flex-col">
          <div className="flex flex-col-reverse justify-between sm:flex-row">
            <h3 className="font-bold h3">
              <Link
                to={`/projects/${projectSlug}/phases/${phaseSlug}/plots/${id}`}
              >
                {formatName(plot)}
              </Link>
            </h3>

            <StatusLabel
              status={status}
              className="!flex-row font-medium sm:!flex-row-reverse"
            />
          </div>

          <span className="font-normal body">
            {available ? formatPrice(plot) : "Onbeschikbaar"}
          </span>
        </div>

        {/* Icons */}
        <div className="flex justify-between gap-2 text-base select-none lg:gap-8">
          {living_surface && (
            <span
              className="flex gap-x-2 items-center lg lg:items-start lg:flex-col lg:basis-[33%]"
              title="Woonoppervlakte"
            >
              <span className="text-lg">
                <FontAwesomeIcon
                  icon={faVectorSquare}
                  className="text-primary"
                />
              </span>
              <span className="text-sm line-clamp-1 text-nowrap">
                {living_surface} m<sup>2</sup>
              </span>
            </span>
          )}

          {room_count && (
            <span
              className="flex gap-x-2 items-center lg:flex-col lg:items-start lg:basis-[33%]"
              title="Aantal kamers"
            >
              <span className="text-lg">
                <FontAwesomeIcon icon={faChair} className="text-primary" />
              </span>
              <span className="text-sm line-clamp-1 text-nowrap">
                {room_count}
                <span className="hidden lg:inline">
                  {" "}
                  {parseInt(room_count) === 1 ? "kamer" : "kamers"}
                </span>
              </span>
            </span>
          )}

          {sun_position_outdoor && (
            <span
              className="flex items-center gap-x-2 lg:flex-col lg:items-start lg:basis-[33%]"
              title="Zonligging"
            >
              <span className="text-lg">
                <FontAwesomeIcon icon={faCompass} className="text-primary" />
              </span>
              <span className="text-sm line-clamp-1">
                {sun_position_outdoor}
              </span>
            </span>
          )}
        </div>
      </div>

      {/* Buttons */}
      <div className="flex flex-wrap items-start gap-1 col-span-full xs:gap-3 sm:col-start-2">
        <CustomLink
          to={`/projects/${projectSlug}/phases/${phaseSlug}?layer=${layer.id}&hotspot=${hotspot.id}`}
          state
          variant="primary"
          icon={faLocationCrosshairs}
          onClick={() => {
            // scrollToTopInstance
            //   .getTarget()
            //   ?.scrollIntoView({ behavior: "smooth" });
          }}
          className="grow sm:grow-0"
        >
          <span className="leading-normal whitespace-nowrap">
            Bekijk in gebouw
          </span>
        </CustomLink>

        <CustomLink
          to={`/projects/${projectSlug}/phases/${phaseSlug}/plots/${id}`}
          variant="primary"
          icon={faInfoCircle}
          onClick={() => {
            // scrollToTopInstance
            //   .getTarget()
            //   ?.scrollIntoView({ behavior: "smooth" });
          }}
          className="grow sm:grow-0"
        >
          <span className="leading-normal whitespace-nowrap">Meer details</span>
        </CustomLink>
      </div>
    </Component>
  );
}
