class scrollToTop {
  private static instance: scrollToTop;
  private target;

  constructor(target?: HTMLDivElement) {
    if (scrollToTop.instance) {
      return scrollToTop.instance;
    }

    if (target) {
      this.target = target;
      scrollToTop.instance = this;
    }

    return this;
  }

  getTarget() {
    return this.target;
  }

  setTarget(target: HTMLDivElement) {
    this.target = target;
  }
}

const scrollToTopInstance = new scrollToTop();
export default scrollToTopInstance;
