import {
  Grid,
  HousetypeFilter,
  LayerNav,
  ListSection,
  MobilePopup,
  Preview,
  PriceFilter,
  ProjectMap,
  RoomFilter,
  StatusFilter,
  SunPositionFilter,
  SurfaceFilter,
} from "@/components";
import "@/components/Filter/Filter.css";
import SmallScreen from "@/components/Filter/SmallScreen";
import { PhaseLoader, PhaseQuery } from "@/loaders";
import {
  groupByLayer,
  groupPlotValuesByKey,
  keyBy,
  matchesFilters,
} from "@/services";
import { Hotspot, Layer, Plot } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useRouteLoaderData, useSearchParams } from "react-router-dom";
import { ConfigProvider } from "antd";
import useActiveLayer from "@/hooks/useActiveLayer";

export default function PhasePage() {
  const { projectSlug, phaseSlug } = useRouteLoaderData("phase") as Awaited<
    ReturnType<ReturnType<typeof PhaseLoader>>
  >;

  const {
    data: { project, phase, statuses, plots, layers, hotspots },
  } = useSuspenseQuery(PhaseQuery(projectSlug, phaseSlug));

  const enabledFilters = Object.entries(phase.filters).reduce<string[]>(
    (accumulator, [key, enabled]) => {
      if (parseInt(enabled) === 0) return accumulator;

      accumulator.push(key);
      return accumulator;
    },
    []
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const urlHotspot = searchParams.get("hotspot");
  const activeHotspot = urlHotspot ? hotspots[urlHotspot] : null;

  const defaultFilteredPlots = keyBy(
    phase.plots.filter((plot) =>
      matchesFilters(plot, searchParams, statuses, enabledFilters)
    ),
    "id"
  );

  const [filteredPlots, setFilteredPlots] = useState(defaultFilteredPlots);
  const plotsByLayer = groupByLayer(phase.hotspots, layers, plots);

  const filteredPlotsByLayer = groupByLayer(
    phase.hotspots,
    layers,
    filteredPlots
  );

  const fieldValues = groupPlotValuesByKey(project.plots);

  const { activeLayer } = useActiveLayer(filteredPlotsByLayer);

  const plotHotspotById = phase.hotspots.reduce<{ [key: Plot["id"]]: Hotspot }>(
    (accumulator, hotspot) => {
      if (hotspot.entity_type !== "App\\Models\\Plot") return accumulator;

      accumulator[hotspot.entity_id] = hotspot;
      return accumulator;
    },
    {}
  );

  const handleLayerNavClick = ({ id }: Layer) => {
    searchParams.set("layer", id.toString());
    searchParams.delete("hotspot");
    setSearchParams(searchParams);

    if (!showMap) {
      setShowMap(true);
    }
  };

  // logic for preview map for small devices
  const [showMap, setShowMap] = useState(false);
  const toggleMapVisibility = () => {
    setShowMap(!showMap);
  };

  const handleResize = () => {
    setShowMap(window.innerWidth > 1024);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setFilteredPlots(
      keyBy(
        project.plots.filter((plot) =>
          matchesFilters(plot, searchParams, statuses, enabledFilters)
        ),
        "id"
      )
    );

    return () => {
      setFilteredPlots(defaultFilteredPlots);
    };
  }, [searchParams, statuses, plots]);

  return (
    <div className="pb-13">
      <ConfigProvider theme={{ cssVar: true }}>
        {/* Small screen */}
        <SmallScreen
          statuses={statuses}
          value={fieldValues}
          onClick={toggleMapVisibility}
          mapStatus={showMap}
          enabledFilters={enabledFilters}
          className={clsx(
            "h-0 sticky top-5 left-0 right-0 z-50 lg:hidden",
            showMap && "!fixed"
          )}
        />

        {/* Big screen filters */}
        <div className="hidden sticky z-50 h-0 top-5 mx-auto max-w-grid lg:max-w-[calc(86rem+3.625rem)] lg:block">
          <div className="p-1 -translate-y-1/2 bg-white border rounded-md border-black/10 gap-x-1 md:flex-nowrap lg:shadow-filterBar lg:flex lg:px-5">
            <PriceFilter
              showMinPrice={true}
              values={Array.from(fieldValues.price.values())}
            />

            <HousetypeFilter
              values={Array.from(fieldValues.house_type_name.values())}
            />

            <div className="w-px h-8 bg-black/10"></div>

            {enabledFilters.includes("statuses") &&
              fieldValues.status.size > 0 && (
                <StatusFilter
                  values={Array.from(fieldValues.status.values())}
                  statuses={statuses}
                />
              )}

            {enabledFilters.includes("room_count") &&
              fieldValues.room_count.size > 0 && (
                <RoomFilter
                  values={Array.from(fieldValues.room_count.values())}
                />
              )}

            {enabledFilters.includes("sun_position_outdoor") &&
              fieldValues.sun_position_outdoor.size > 0 && (
                <SunPositionFilter
                  values={Array.from(fieldValues.sun_position_outdoor.values())}
                />
              )}

            {enabledFilters.includes("living_surface") &&
              fieldValues.living_surface.size > 0 && (
                <SurfaceFilter
                  values={Array.from(fieldValues.living_surface.values())}
                />
              )}
          </div>
        </div>
      </ConfigProvider>

      {/* Note: we can't toggle display:none/block instead of rerendering because Leaflet wont render properly */}
      {showMap && (
        <ProjectMap
          project={project}
          statuses={statuses}
          layers={layers}
          activeLayer={activeLayer}
          plotsById={plots}
          filteredPlotsByLayer={filteredPlotsByLayer}
          hotspots={hotspots}
          filteredPlots={filteredPlots}
          className={clsx(
            "fixed inset-0 z-40 overflow-hidden w-dvw h-dvh bg-secondary lg:block lg:relative lg:inset-auto"
          )}
        />
      )}

      <div
        className={clsx("relative bg-secondary lg:hidden", showMap && "hidden")}
      >
        <Preview
          background={activeLayer.background}
          handleClick={toggleMapVisibility}
        >
          Bekijk interactieve kaart
        </Preview>
      </div>

      {activeHotspot && (
        <MobilePopup
          plot={plots[activeHotspot.entity_id]}
          status={statuses[plots[activeHotspot.entity_id].status]}
          className="fixed z-40 pb-5 pl-6 pr-2 overflow-hidden bg-white bottom-12 pt-7 rounded-t-2xl lg:hidden"
        />
      )}

      {/* Layer navigation for desktop */}
      <LayerNav
        layers={phase.layers}
        activeLayer={activeLayer}
        handleLayerNavClick={handleLayerNavClick}
        className="left-auto right-auto z-40 hidden mx-auto lg:w-auto lg:max-w-grid lg:sticky lg:-translate-y-1/2 lg:top-40 lg:px-6 lg:block grid:px-0"
      />

      {/* Plot sections */}
      <div
        className={clsx(
          "z-30 flex flex-col top-0 px-6 bg-white/90 backdrop-blur-xl lg:top-0 lg:sticky lg:px-0 lg:mb-12"
        )}
      >
        <div className="w-full mx-auto max-w-grid">
          <div className="pt-4 pb-6 lg:pt-10 lg:pb-18 lg:px-6 grid:px-0">
            <h1 className="font-extrabold h1">Aanbod</h1>
          </div>
          <hr className="hidden my-0 border-black/10 lg:block" />
        </div>
      </div>

      <Grid className="flex flex-col gap-y-16 lg:gap-y-32">
        {phase.layers.map((layer) => {
          return (
            <ListSection
              key={layer.id}
              layer={layer}
              plots={plotsByLayer[layer.id] || []}
              filteredPlots={filteredPlotsByLayer[layer.id] || []}
              statuses={statuses}
              hotspots={plotHotspotById}
            />
          );
        })}
      </Grid>

      {/* Layer navigation for mobile */}
      <LayerNav
        layers={phase.layers}
        activeLayer={activeLayer}
        handleLayerNavClick={handleLayerNavClick}
        className={clsx(
          "sticky bottom-0 left-auto right-auto z-40 w-full mx-auto lg:hidden",
          showMap && "!fixed"
        )}
      />
    </div>
  );
}
